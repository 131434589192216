import { CoreModule } from '@abp/ng.core';
import { NgbModule, NgbDropdownModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { OrderByPipe } from './pipes/order.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { NoDataComponent } from './nodata/nodata.component';
import { SortPipe } from './pipes/order-sort.pipe';
import { SearchListComponent } from './search-list/search-list.component';
import { FilterExactMatchPipe } from './pipes/filter-exact-match.pipe';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { DateFormatterPipe } from './pipes/date-formatter.pipe';
import { DateRangeSelectionPanelComponent } from './date-range-selection-panel/date-range-selection-panel.component';
import { TreeviewModule } from 'ngx-treeview';
import { DropdownTreeviewSelectModule } from './designer/treeview/dropdown-treeview-select.module';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { RichTextModule } from './rich-text/rich-text.module';
import { AfterIfDirective } from './after-if.directive';
import { NgxDatatableResizerDirective } from './ngx-datatable-resizer.directive';
import { IntegrationTooltipFormatterPipe } from './pipes/integration-tooltip-formatter.pipe';
import { DurationPipe } from './pipes/duration-formatter.pipe';
import { FilterPanelComponent } from './filter-panel/components/filter-panel.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileDownloadHelperService } from './services/file-download-helper.service';
import { FlowVaNavigatorComponent } from './flow-va-navigator/flow-va-navigator.component';
import { BlockUIModule } from 'ng-block-ui';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { ColumnConfiguratorComponent } from './column-configurator/components/column-configurator/column-configurator.component';

@NgModule({
  declarations: [
    OrderByPipe,
    FilterPipe,
    DurationPipe,
    NoDataComponent,
    SortPipe,
    SearchListComponent,
    FilterExactMatchPipe,
    DateTimePickerComponent,
    DateFormatterPipe,
    AfterIfDirective,
    NgxDatatableResizerDirective,
    DateRangeSelectionPanelComponent,
    IntegrationTooltipFormatterPipe,
    SidebarMenuComponent,
    FilterPanelComponent,
    FlowVaNavigatorComponent,
    FileDragDropDirective,
    CheckBoxComponent,
    ColumnConfiguratorComponent,
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgbModule,
    NgbDatepickerModule,
    NgxValidateCoreModule,
    NzSwitchModule,
    RichTextModule,
    TreeviewModule.forRoot(),
    DropdownTreeviewSelectModule,
    NzTabsModule,
    NzDropDownModule,
    BlockUIModule,
    NgSelectModule,
    DragDropModule,
  ],
  exports: [
    OrderByPipe,
    SortPipe,
    FilterPipe,
    DurationPipe,
    IntegrationTooltipFormatterPipe,
    SearchListComponent,
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NzSwitchModule,
    RichTextModule,
    SidebarMenuComponent,
    NoDataComponent,
    FilterExactMatchPipe,
    DateTimePickerComponent,
    DateFormatterPipe,
    DateRangeSelectionPanelComponent,
    AfterIfDirective,
    NgxDatatableResizerDirective,
    FlowVaNavigatorComponent,
    FilterPanelComponent,
    FileDragDropDirective,
    CheckBoxComponent,
    ColumnConfiguratorComponent,
  ],
  providers: [
    // other providers
    FileDownloadHelperService,
  ],
})
export class SharedModule {}
