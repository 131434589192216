import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomSettingsService } from '../proxy/custom-settings.service';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  API_KEY,
  CONVERSATION_SUMMARIZATION_PHRASE,
  DEPLOYMENT_NAME,
  MODEL,
  MODEL_OPTIONS,
  TEXT_RESPONSE_GENERATION_PHRASE,
  PROVIDER,
  RESOURCE_NAME,
  SERVICE_URL,
  UTTERANCE_GENERATION_PHRASE,
  PROJECT_GENERATION_PHRASE,
} from './openai-integration.consts';
import { CustomSettingsDto } from '../proxy/custom-settings.model';
import { TOASTER_LIFE } from 'src/app/shared/shared.consts';
import {
  AI_FEATURE_CONVERSATION_SUMMARIZATION,
  AI_FEATURE_LIVECHAT_SUMMARIZATION,
  AI_FEATURE_PROJECT_GENERATION,
  AI_FEATURE_TEXT_RESPONSE_GENERATION,
  AI_FEATURE_UTTERANCE_GENERATION,
} from 'src/app/virtual-agents/proxy/open-ai/open-ai.const';

@Component({
  selector: 'cai-openai-integration-settings',
  templateUrl: './openai-integration-settings.component.html',
  styleUrls: ['./openai-integration-settings.component.scss'],
})
export class OpenAIIntegrationSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  providerOptions = [];
  modelOptions = [];
  selectedProvider;
  azureOpenAIProvider = '';
  openAIProvider;
  isUtteranceGenerationFeatureEnabled = false;
  isTextResponseGenerationFeatureEnabled = false;
  isConversationSummarizationFeatureEnabled = false;
  isLiveChatSummarizationFeatureEnabled = false;
  isProjectGenerationFeatureEnabled = false;

  constructor(
    private config: ConfigStateService,
    private fb: UntypedFormBuilder,
    private settingsService: CustomSettingsService,
    private toaster: ToasterService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit(): void {
    this.azureOpenAIProvider = this.localizationService.instant(
      'Administration::OpenAiSettings:Provider:AzureOpenAi'
    );
    this.openAIProvider = this.localizationService.instant(
      'Administration::OpenAiSettings:Provider:OpenAi'
    );
    this.providerOptions.push(this.azureOpenAIProvider);
    this.providerOptions.push(this.openAIProvider);
    this.modelOptions = MODEL_OPTIONS;
    this.buildForm();
    this.checkFeatures();
  }

  buildForm() {
    this.selectedProvider = this.config.getSetting(PROVIDER) ?? this.azureOpenAIProvider;
    this.form = this.fb.group({
      provider: [this.selectedProvider, [Validators.required]],
      apiKey: [this.config.getSetting(API_KEY), [Validators.required]],
      utteranceGeneration: [UTTERANCE_GENERATION_PHRASE],
      conversationSummarization: [CONVERSATION_SUMMARIZATION_PHRASE],
      liveChatSummarization: [CONVERSATION_SUMMARIZATION_PHRASE],
      textResponseGeneration: [TEXT_RESPONSE_GENERATION_PHRASE],
      projectGeneration: [PROJECT_GENERATION_PHRASE],
      model: [this.config.getSetting(MODEL), [Validators.required]],
      serviceUrl: [this.config.getSetting(SERVICE_URL), [Validators.required]],
      deploymentName: [this.config.getSetting(DEPLOYMENT_NAME), [Validators.required]],
      resourceName: [this.config.getSetting(RESOURCE_NAME), [Validators.required]],
    });
  }

  checkFeatures() {
    this.isUtteranceGenerationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_UTTERANCE_GENERATION) === 'true';
    this.isTextResponseGenerationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_TEXT_RESPONSE_GENERATION) === 'true';
    this.isConversationSummarizationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_CONVERSATION_SUMMARIZATION) === 'true';
    this.isLiveChatSummarizationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_LIVECHAT_SUMMARIZATION) === 'true';
    this.isProjectGenerationFeatureEnabled =
      this.config.getFeature(AI_FEATURE_PROJECT_GENERATION) === 'true';
  }
  submitForm() {
    if (!this.isFormValid()) {
      return;
    }

    const settings: CustomSettingsDto[] = [];
    settings.push(
      {
        name: PROVIDER,
        value: this.form.get('provider').value,
      },
      {
        name: API_KEY,
        value: this.form.get('apiKey').value,
      },
      {
        name: MODEL,
        value: this.form.get('model').value,
      },
      {
        name: SERVICE_URL,
        value: this.form.get('serviceUrl').value,
      },
      {
        name: DEPLOYMENT_NAME,
        value: this.form.get('deploymentName').value,
      },
      {
        name: RESOURCE_NAME,
        value: this.form.get('resourceName').value,
      }
    );

    this.settingsService.save(settings).subscribe(
      () => {
        this.toaster.success('::CustomSettings:Save:Success', null, { life: TOASTER_LIFE });
      },
      (error) => {
        const businessExceptionErrorCodePrefix = 'Raven:';

        // If the error is caused by a business exception, then the related error message will be shown
        // via global error handling popup, so no need to display an additional error toaster here.
        if (!error?.error?.error?.code.startsWith(businessExceptionErrorCodePrefix)) {
          this.toaster.error('::CustomSettings:Save:Error', null, { life: TOASTER_LIFE });
          console.log(error);
        }
      }
    );
  }

  setSelectedProvider() {
    this.selectedProvider = this.form.get('provider').value;
  }

  isOpenAiSelected() {
    return this.selectedProvider === this.openAIProvider;
  }

  isAzureOpenAiSelected() {
    return this.selectedProvider === this.azureOpenAIProvider;
  }

  isFormValid() {
    if (this.isOpenAiSelected()) {
      return (
        this.form.get('provider').value &&
        this.form.get('apiKey').value &&
        this.form.get('model').value
      );
    } else {
      return (
        this.form.get('provider').value &&
        this.form.get('apiKey').value &&
        this.form.get('serviceUrl').value &&
        this.form.get('deploymentName').value &&
        this.form.get('resourceName').value
      );
    }
  }
}
