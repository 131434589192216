import { query, group, style, animate } from '@angular/animations';
import { AbstractControl } from '@angular/forms';
import * as go from 'gojs';
import { filter, take } from 'rxjs/operators';
import { RICH_TEXT_EDITOR_EMPTY_VALUE } from '../../rich-text/proxy/rich-text-editor.const';
import { QuillValueModel } from '../../rich-text/proxy/rich-text-editor.model';
import { nodeNames } from '../gojs/palette.consts';
import {
  API_KEY,
  DEPLOYMENT_NAME,
  MODEL,
  PROVIDER,
  RESOURCE_NAME,
  SERVICE_URL,
} from 'src/app/settings/openai-integration-settings/openai-integration.consts';
import { FlowModel, NodeModel } from '../proxy/designer.model';
import { START_NODE_TYPE_ID } from '@utils';

export const ContextConverter = (context) => {
  const contextObj = context;
  const staticArr = Object.values(contextObj?.staticContext).map((item: any) => {
    return { ...item, type: 'static', value: item?.key, isDeleted: false };
  });
  const dynamicArr = Object.values(contextObj?.dynamicContext).map((item: any) => {
    return { ...item, type: 'dynamic', isDeleted: false };
  });

  const entityArr =
    contextObj?.entityContext !== null
      ? Object.values(contextObj?.entityContext).map((item: any) => {
          return {
            ...item,
            type: item.type,
            id: item.entityId,
            isDeleted: false,
          };
        })
      : [];
  return [...dynamicArr, ...entityArr, ...staticArr];
};

export const isLoopBack = (link) => {
  if (!link) return false;
  return;
};

export const dropOntoLink = (e, oldlink) => {
  if (!(oldlink instanceof go.Link)) return;
  const diagram = e.diagram;
  const newNode = diagram.selection.first();
  if (!(newNode instanceof go.Node)) return;
  // Node has a link, do not link any nodes
  if (
    newNode.findLinksConnected(oldlink.data.fromPort).count ||
    newNode.findLinksConnected(oldlink.data.toPort).count > 0
  )
    return;
  if (
    !isLoopBack(oldlink) &&
    newNode !== null &&
    newNode?.data !== undefined &&
    newNode?.data.name !== nodeNames.start &&
    newNode?.data.name !== nodeNames.end &&
    newNode?.data.name !== nodeNames.exit &&
    newNode?.data.name !== nodeNames.returnToStart &&
    newNode?.data.name !== nodeNames.condition
  ) {
    const aboveNodeKey = oldlink?.data?.from;
    const aboveNodeOutPort = oldlink?.data?.fromPort;

    const underNodeKey = oldlink?.data?.to;
    const underNodeInPort = oldlink?.data?.toPort;

    const newNodeKey = newNode?.data?.key;
    const newNodeInPort = 'in';
    let newNodeOutPort = 'out';
    const newNodeConnectPort = 'connect';
    const newNodeConfirmedPort = 'confirmed';
    const newNodeNoResultPort = 'noresult';
    const newNodeFoundPort = 'found';

    if (newNode?.data.name === nodeNames.callTransfer) {
      newNodeOutPort = newNodeConnectPort;
    } else if (newNode?.data.name === nodeNames.confirmation) {
      newNodeOutPort = newNodeConfirmedPort;
    } else if (newNode?.data.name === nodeNames.listFilter) {
      newNodeOutPort = newNodeNoResultPort;
    } else if (newNode?.data.name === nodeNames.voiceBiometrics) {
      newNodeOutPort = newNodeConfirmedPort;
    } else if (newNode?.data.name === nodeNames.generativeQuestionAnswering) {
      newNodeOutPort = newNodeFoundPort;
    }

    diagram.model.addLinkData({
      from: aboveNodeKey,
      fromPort: aboveNodeOutPort,
      to: newNodeKey,
      toPort: newNodeInPort,
    });

    diagram.model.addLinkData({
      from: newNodeKey,
      fromPort: newNodeOutPort,
      to: underNodeKey,
      toPort: underNodeInPort,
    });

    diagram.model.removeLinkData(oldlink.data);
  }
};

export const leftSliderAnimation = [
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(-100%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)' })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({
          transform: 'translateX(0%)',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          opacity: 0,
        }),
        animate('.3s ease-out', style({ transform: 'translateX(50%)' })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

export const rightSliderAnimation = [
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(50%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)' })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({
          transform: 'translateX(0%)',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          opacity: 0,
        }),
        animate('.3s ease-out', style({ transform: 'translateX(-100%)' })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

export const convertRichTextToPlainText = (str: string) => {
  let quillValue: QuillValueModel;
  try {
    quillValue = JSON.parse(str);
  } catch {}

  if (typeof quillValue === 'object' && quillValue.ops && quillValue.ops.length) {
    let convertedQuillValue = '';

    const lastOp = quillValue.ops[quillValue.ops.length - 1];

    if (typeof lastOp.insert === 'string') {
      lastOp.insert = clearNewLineSuffix(lastOp.insert);
    }

    quillValue.ops.forEach((op) => {
      if (typeof op.insert === 'string') {
        convertedQuillValue += op.insert;
      } else if (typeof op.insert === 'object') {
        convertedQuillValue += '{{' + op.insert.mention.key + '}}';
      }
    });

    return convertedQuillValue;
  }

  return str;
};

export const setTouchedIfValueChanged = (
  source: AbstractControl,
  target: AbstractControl | (() => void)
) => {
  source.valueChanges
    .pipe(
      filter((value) => !checkIfRichTextValueIsEmpty(value)),
      take(1)
    )
    .subscribe(() => {
      if (target instanceof AbstractControl) {
        target.patchValue(true, { onlySelf: true });
      } else if (target instanceof Function) {
        target();
      }
    });
};

export const clearNewLineSuffix = (value: string): string => {
  if (value) {
    if (value.endsWith('<br>')) {
      value = clearNewLineSuffix(value.slice(0, -4));
    } else if (value.endsWith('\r\n')) {
      value = clearNewLineSuffix(value.slice(0, -2));
    } else if (value.endsWith('\n')) {
      value = clearNewLineSuffix(value.slice(0, -1));
    }
  }

  return value;
};

export const checkIfRichTextValueIsEmpty = (value) =>
  value === RICH_TEXT_EDITOR_EMPTY_VALUE ||
  value === '' ||
  value === '<br>' ||
  value === '\r\n' ||
  value === '\n';

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const setContextIconTooltip = (localizationService) => {
  setTimeout(() => {
    const contextIcons = document.getElementsByClassName('multiple-tag-icon');

    const onMouseEnter = (e) => {
      if (!e.target.title)
        e.target.title = localizationService.instant('::ExpressionInputContextIconTooltip');
    };
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < contextIcons.length; i++) {
      contextIcons[i].addEventListener('mouseenter', onMouseEnter);
    }
  }, 50);
};

export const checkIfNodeInputIsDynamic = (type) => {
  return type === 'Dynamic' || type === 'ExtensibleDynamic';
};

export const fancyTimeFormat = (duration: number) => {
  const hours = ~~(duration / 3600); //eslint-disable-line no-bitwise
  const minutes = ~~((duration % 3600) / 60); //eslint-disable-line no-bitwise
  const seconds = ~~duration % 60; //eslint-disable-line no-bitwise

  let time = '';

  if (hours > 0) {
    time += '' + hours + ':' + (minutes < 10 ? '0' : '');
  }

  time += '' + minutes + ':' + (seconds < 10 ? '0' : '');
  time += '' + seconds;

  return time;
};

export const getStorageKey = (configService, storageKey) => {
  const currentUserName = configService.getOne('currentUser').userName;
  const currentTenantName = configService.getOne('currentTenant').name;
  const tenantUserNameKey = currentTenantName + currentUserName;
  return storageKey + tenantUserNameKey;
};

export const clearHighlightedsAndZorder = (diagram) => {
  diagram.clearHighlighteds();
  diagram.commit(() => {
    diagram.links.each((link) => {
      diagram.model.set(link, 'zOrder', 0);
    });
  }, 'modified zOrder');
};

export const areOpenAiSettingsFilled = (configService, localizationService) => {
  const selectedProvider = configService.getSetting(PROVIDER) ?? '';
  const apiKeyFilled = configService.getSetting(API_KEY) !== '';
  switch (selectedProvider) {
    case localizationService.instant('Administration::OpenAiSettings:Provider:AzureOpenAi'):
      const serviceUrlFilled = configService.getSetting(SERVICE_URL) !== '';
      const deploymentNameFilled = configService.getSetting(DEPLOYMENT_NAME) !== '';
      const resourceNameFilled = configService.getSetting(RESOURCE_NAME) !== '';
      return apiKeyFilled && serviceUrlFilled && deploymentNameFilled && resourceNameFilled;
    case localizationService.instant('Administration::OpenAiSettings:Provider:OpenAi'):
      const modelFilled = configService.getSetting(MODEL) !== '';
      return apiKeyFilled && modelFilled;
    default:
      return false;
  }
};

export const checkFlow = (flow: FlowModel, nodeTemplates: NodeModel[]): boolean => {
  if (flow && nodeTemplates?.length) {
    const startNodes = flow.nodeDataArray.filter((n) => n.typeId === START_NODE_TYPE_ID);

    if (startNodes.length !== 1) {
      const otherNodes = flow.nodeDataArray.filter((n) => n.typeId !== START_NODE_TYPE_ID);
      let startNodeTemplate = nodeTemplates.find((t) => t.typeId === START_NODE_TYPE_ID);
      startNodeTemplate = JSON.parse(JSON.stringify(startNodeTemplate));
      startNodeTemplate.key = Math.min(...otherNodes.map((n) => n.key)) - 1;

      const locX = flow.position ? Number(flow.position.split(',')[0]) + 77 : 0;
      const locY = flow.position ? Number(flow.position.split(',')[1]) + 25 : 0;

      startNodeTemplate.loc = locX + ' ' + locY;

      flow.linkDataArray = flow.linkDataArray.filter(
        (l) => !startNodes.some((n) => n.key === l.from)
      );

      flow.nodeDataArray = [startNodeTemplate, ...otherNodes];

      return true;
    }
  }

  return false;
};
