import {
  AuthService,
  ConfigStateService,
  LocalizationService,
  PermissionService,
  ReplaceableComponentsService,
  RoutesService,
} from '@abp/ng.core';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderBarVisibilityService } from '@proxy/loader-bar-visibility.service';
import {
  PremiumReportingAddMenuService,
  PremiumReportingConfigService,
  PremiumReportingMenuService,
} from '@sestek/premium-reporting/config';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs';
import { BotHubSettingsComponent } from './settings/bothub-settings/bothub-settings.component';
import { NavItemsComponent } from './shared/nav-items/nav-items.component';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TOOLTIP_DELAY } from './shared/shared.consts';
import { CaIntegrationSettingsComponent } from './settings/ca-integration-settings/ca-integration-settings.component';
import { NotificationService } from '@sestek/notification';
import { FooterComponent } from './shared/footer/footer.component';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { OpenAIIntegrationSettingsComponent } from './settings/openai-integration-settings/openai-integration-settings.component';
import {
  AI_FEATURE_CONVERSATION_SUMMARIZATION,
  AI_FEATURE_GENERATIVE_QUESTION_ANSWERING,
  AI_FEATURE_LIVECHAT_SUMMARIZATION,
  AI_FEATURE_TEXT_RESPONSE_GENERATION,
  AI_FEATURE_UTTERANCE_GENERATION,
} from './virtual-agents/proxy/open-ai/open-ai.const';
import { LogoComponent } from './shared/nav-items/logo/logo.component';

@Component({
  selector: 'cai-root',
  template: `
    <abp-loader-bar *ngIf="isLoaderVisible"></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <cai-column-configurator></cai-column-configurator>
  `,
  providers: [NgbTooltipConfig],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoaderVisible = true;
  loaderBarVisibilitySubscription: Subscription;

  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private loaderBarVisibilityService: LoaderBarVisibilityService,
    private oAuthService: OAuthService,
    private authService: AuthService,
    private settingTabs: SettingTabsService,
    private localizationService: LocalizationService,
    private premiumReportingConfigService: PremiumReportingConfigService,
    private premiumReportingMenuService: PremiumReportingMenuService,
    private premiumReportingAddMenuService: PremiumReportingAddMenuService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private config: ConfigStateService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private routes: RoutesService
  ) {
    Date.prototype.toJSON = function () {
      const copiedDate = new Date(this.getTime());
      const result = copiedDate.toISOString();
      return result;
    };
    this.ngbTooltipConfig.openDelay = TOOLTIP_DELAY;

    this.notificationService.initialize();
  }

  get hasLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  ngOnInit() {
    const isPremiumReportingFeatureEnabled = this.config.getFeature(
      'PremiumReportingGroup.PremiumReporting'
    );
    if (
      this.permissionService.getGrantedPolicy('PremiumReporting.PremiumReports') &&
      isPremiumReportingFeatureEnabled === 'true'
    ) {
      this.getPremiumReportingMenuList();
      this.premiumReportingMenuService.premiumReportingMenuChange.subscribe(() =>
        this.getPremiumReportingMenuList()
      );
    }
    this.settingTabs.add([
      {
        name: this.localizationService.instant('::BotHubSettings'),
        order: 3,
        component: BotHubSettingsComponent,
        requiredPolicy: 'BotHub.SettingManagement',
      },
      {
        name: this.localizationService.instant('Administration::CaIntegrationSettings'),
        order: 4,
        component: CaIntegrationSettingsComponent,
        requiredPolicy: 'AbpIdentity.SettingManagement',
      },
    ]);

    const isOpenAiSettingsEnabled =
      this.config.getFeature(AI_FEATURE_CONVERSATION_SUMMARIZATION) === 'true' ||
      this.config.getFeature(AI_FEATURE_UTTERANCE_GENERATION) === 'true' ||
      this.config.getFeature(AI_FEATURE_GENERATIVE_QUESTION_ANSWERING) === 'true' ||
      this.config.getFeature(AI_FEATURE_LIVECHAT_SUMMARIZATION) === 'true' ||
      this.config.getFeature(AI_FEATURE_TEXT_RESPONSE_GENERATION) === 'true';

    if (isOpenAiSettingsEnabled) {
      this.settingTabs.add([
        {
          name: this.localizationService.instant('Administration::OpenAiSettings'),
          order: 5,
          component: OpenAIIntegrationSettingsComponent,
          requiredPolicy: 'AbpIdentity.SettingManagement',
        },
      ]);
    }

    if (!this.hasLoggedIn) {
      this.authService.navigateToLogin();
    }

    this.replaceableComponents.add({
      component: NavItemsComponent,
      key: eThemeBasicComponents.NavItems,
    });

    this.replaceableComponents.add({
      component: FooterComponent,
      key: eThemeLeptonComponents.ApplicationLayoutFooter,
    });

    this.replaceableComponents.add({
      component: LogoComponent,
      key: eThemeBasicComponents.Logo,
    });

    this.loaderBarVisibilitySubscription =
      this.loaderBarVisibilityService.loaderBarVisibilityChanged.subscribe((isVisible: boolean) => {
        this.isLoaderVisible = isVisible;
      });
  }

  getPremiumReportingMenuList() {
    this.premiumReportingConfigService.get({ maxResultCount: 50 }).subscribe((res) => {
      this.premiumReportingAddMenuService.addMenu(res.items);
      const prMenu = {
        order: 7,
      };
      this.routes.patch('PremiumReporting::Menu:PremiumReports', prMenu);
    });
  }

  ngOnDestroy() {
    this.loaderBarVisibilitySubscription.unsubscribe();
  }
}
