import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProjectFilterDto, ProjectVirtualAgentDto } from 'src/app/projects/proxy/project.models';
import { DateRangeSelectionConfigurationModel } from 'src/app/shared/models/date-range-selection-configuration.model';
import { AgentDto } from 'src/app/virtual-agents/proxy';
import { FilterOptions, FilterValueModel } from 'src/app/shared/models/filter-panel.model';
import { ToasterService } from '@abp/ng.theme.shared';
import { forkJoin } from 'rxjs';
import {
  DEFAULT_DATE_RANGE_IN_DAYS,
  MAX_SEARCH_DATE_RANGE_IN_DAYS,
} from '../../../dashboard/models/dashboard.const';
import { DateSelectionValueModel } from 'src/app/shared/models/date-selection-value.model';
import { TOASTER_LIFE } from 'src/app/shared/shared.consts';
import { PredefinedDateRange } from 'src/app/shared/enums/predefined-date-range';
import {
  CustomerInformationType,
  SentimentLevel,
} from 'src/app/conversation/proxy/conversation.model';
import { ObjectHelper } from '../../helpers/object.helper';
import { FilterService } from '../proxy/filter-panel.service';
import { RATING_OPTIONS } from 'src/app/conversation/proxy/conversation.consts';

@Component({
  selector: 'cai-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterPanelComponent implements OnInit, AfterViewInit {
  isProjectSelected = false;
  form: UntypedFormGroup;
  allProjects: ProjectFilterDto[] = [];
  allVirtualAgents: AgentDto[] = [];
  ratingOptions: number[] = [];
  sentimentOptions: string[] = [];
  cascadingVirtualAgents: ProjectVirtualAgentDto[] = [];
  allChannels: string[] = [];
  isFilterProjectLoading = true;
  customerInformationTypes: { key: number; value: string }[];

  filterValue: FilterValueModel = {};
  showLoader = false;

  @Input() dashboardComboFiltersModelKey;
  @Input() dashboardDateFilterModelKey;
  @Input() filterOptions: FilterOptions;

  @Output()
  public filtersChanged: EventEmitter<FilterValueModel> = new EventEmitter(true);

  dateSelectionConfiguration: DateRangeSelectionConfigurationModel = {
    useNoEndDate: false,
    useShortcuts: true,
  };
  dateSelectionValue: DateSelectionValueModel;

  calculatedStartDate: Date;
  calculatedEndDate: Date;

  constructor(
    private filterService: FilterService,
    private fb: UntypedFormBuilder,
    private toaster: ToasterService
  ) {
    this.customerInformationTypes = ObjectHelper.getEnumKeyValuePairs(CustomerInformationType);
  }

  ngAfterViewInit(): void {
    forkJoin([
      this.filterService.getProjectOptions(),
      this.filterService.getBackwardCompatibleChannelIntegrationTypes(),
    ]).subscribe(([allProjectsResult, allChannelsResult]) => {
      this.allProjects = allProjectsResult;
      this.allChannels = allChannelsResult.platforms;

      this.isFilterProjectLoading = false;
      this.setupFilters();
    });

    this.filterOptions.dataLoaded?.subscribe(() => (this.showLoader = false));
  }

  setupFilters() {
    this.setupDateFilter();
    this.setupProjectAndVirtualAgentFilter();
    this.ratingOptions = RATING_OPTIONS;
    this.sentimentOptions = Object.keys(SentimentLevel).filter((item) => {
      return isNaN(Number(item));
    });
    this.onSubmitFilters();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  setVirtualAgentList() {
    this.cascadingVirtualAgents = this.getUpdatedCascadingVirtualAgents(
      this.form.get('filterProject').value
    );

    // Remove selected virtual agents, those are agents of removed projects.
    const currentVirtualAgentsSelection = this.form.get('filterVirtualAgent').value;
    if (currentVirtualAgentsSelection?.length) {
      const updatedVirtualAgentsSelection = currentVirtualAgentsSelection.filter((id) =>
        this.cascadingVirtualAgents?.some((va) => va.id === id)
      );

      this.form.patchValue({ filterVirtualAgent: updatedVirtualAgentsSelection });
    }
  }

  buildForm() {
    this.form = this.fb.group({
      filterConversationId: null,
      filterCustomerInformationType: CustomerInformationType.Phone,
      filterCustomerInformation: null,
      filterProject: [null],
      filterVirtualAgent: [null],
      filterRating: [null],
      filterSentiment: [null],
      filterChannel: [null],
    });

    this.form.get('filterProject').valueChanges.subscribe((selectedProjects) => {
      if (selectedProjects) {
        this.setVirtualAgentList();
        this.isProjectSelected = selectedProjects.length > 0;

        if (selectedProjects.length > 0) {
          this.form.get('filterVirtualAgent').enable();
        } else {
          this.form.get('filterVirtualAgent').disable();
        }
      }
    });
  }

  onClearFilters() {
    this.form.reset();

    this.form.patchValue({ filterCustomerInformationType: CustomerInformationType.Phone });

    this.calculatedStartDate = new Date(
      new Date(new Date().setDate(new Date().getDate() - DEFAULT_DATE_RANGE_IN_DAYS)).setHours(
        0,
        0,
        0,
        0
      )
    );

    this.calculatedEndDate = new Date(
      new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59, 999)
    );
    this.setDateRangeValue();

    localStorage.setItem(
      this.dashboardComboFiltersModelKey,
      JSON.stringify(this.form.getRawValue())
    );
  }

  setDateRangeValue() {
    const calculatedDateSelectionModel: DateSelectionValueModel = {
      start: this.calculatedStartDate,
      end: this.calculatedEndDate,
      noEndDate: false,
      shortcut: -DEFAULT_DATE_RANGE_IN_DAYS,
    };

    if (
      DEFAULT_DATE_RANGE_IN_DAYS !== -PredefinedDateRange.yesterday &&
      DEFAULT_DATE_RANGE_IN_DAYS !== -PredefinedDateRange.today &&
      DEFAULT_DATE_RANGE_IN_DAYS !== -PredefinedDateRange.lastSevenDays &&
      DEFAULT_DATE_RANGE_IN_DAYS !== -PredefinedDateRange.lastThirtyDays
    ) {
      calculatedDateSelectionModel.shortcut = null;
    }

    this.dateSelectionValue = calculatedDateSelectionModel;
  }

  onSubmitFilters() {
    let startDate: Date;
    let endDate: Date;

    if (localStorage.getItem(this.dashboardDateFilterModelKey)) {
      startDate = new Date(this.dateSelectionValue.start);
      endDate = new Date(this.dateSelectionValue.end);
    } else {
      startDate = new Date(this.calculatedStartDate);
      endDate = new Date(this.calculatedEndDate);
    }

    const searchDateRange = Math.abs(startDate.getTime() - endDate.getTime());
    const searchDateRangeInDays = Math.ceil(searchDateRange / (1000 * 3600 * 24));

    if (searchDateRangeInDays > MAX_SEARCH_DATE_RANGE_IN_DAYS) {
      this.toaster.error('::OneMonthMaxDateRangeSelection', null, { life: TOASTER_LIFE });
      return;
    }

    this.filterValue = {
      startDate:
        startDate ||
        new Date(
          new Date(new Date().setDate(new Date().getDate() - DEFAULT_DATE_RANGE_IN_DAYS)).setHours(
            0,
            0,
            0,
            0
          )
        ),
      endDate:
        endDate ||
        new Date(new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59, 999)),
    };

    if (this.filterOptions.filterProject) {
      this.filterValue.projectIds = this.form.get('filterProject').value;
    }
    if (this.filterOptions.filterVirtualAgent) {
      this.filterValue.virtualAgentIds = this.form.get('filterVirtualAgent').value;
    }
    if (this.filterOptions.filterConversationId) {
      this.filterValue.conversationId = this.form.get('filterConversationId').value;
    }
    if (this.filterOptions.filterCustomerInformation) {
      this.filterValue.customerInformationType = this.form.get(
        'filterCustomerInformationType'
      ).value;
      this.filterValue.customerInformation = this.form.get('filterCustomerInformation').value;
    }
    if (this.filterOptions.filterChannel) {
      this.filterValue.channelNames = this.form.get('filterChannel').value;
    }
    if (this.filterOptions.filterRating) {
      this.filterValue.ratings = this.form.get('filterRating').value;
    }
    if (this.filterOptions.filterSentiment) {
      this.filterValue.sentimentLevels = this.form.get('filterSentiment').value;
    }

    localStorage.setItem(
      this.dashboardComboFiltersModelKey,
      JSON.stringify(this.form.getRawValue())
    );
    this.showLoader = true;
    this.filtersChanged.emit(this.filterValue);
  }

  onDateRangeChanges(value: DateSelectionValueModel) {
    localStorage.setItem(this.dashboardDateFilterModelKey, JSON.stringify(value));
  }

  onKeydown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onSubmitFilters();
    }
  }

  private setupDateFilter() {
    const storedDateFilter = localStorage.getItem(this.dashboardDateFilterModelKey);
    if (storedDateFilter) {
      const storedDateFilterObj: DateSelectionValueModel = JSON.parse(storedDateFilter);
      storedDateFilterObj.restoreExistingDate = true;
      this.dateSelectionValue = storedDateFilterObj;
    } else {
      this.calculatedStartDate =
        this.filterValue.startDate ||
        new Date(
          new Date(new Date().setDate(new Date().getDate() - DEFAULT_DATE_RANGE_IN_DAYS)).setHours(
            0,
            0,
            0,
            0
          )
        );

      this.calculatedEndDate =
        this.filterValue.endDate ||
        new Date(new Date(new Date().setDate(new Date().getDate())).setHours(23, 59, 59, 999));

      this.setDateRangeValue();
    }
  }

  private setupProjectAndVirtualAgentFilter() {
    const storedComboFilters = localStorage.getItem(this.dashboardComboFiltersModelKey);
    if (storedComboFilters) {
      const storedComboFiltersObj: any = JSON.parse(storedComboFilters);

      if (storedComboFiltersObj.filterProject?.length) {
        storedComboFiltersObj.filterProject = storedComboFiltersObj.filterProject.filter((id) =>
          this.allProjects.some((p) => p.id === id)
        );
      }

      this.setVirtualAgentList();

      if (storedComboFiltersObj.filterVirtualAgent?.length) {
        const updatedCascadingVirtualAgents = this.getUpdatedCascadingVirtualAgents(
          storedComboFiltersObj.filterProject
        );

        storedComboFiltersObj.filterVirtualAgent = storedComboFiltersObj.filterVirtualAgent.filter(
          (id) => updatedCascadingVirtualAgents.some((va) => va.id === id)
        );
      }

      if (storedComboFiltersObj.filterCustomerInformationType === null) {
        storedComboFiltersObj.filterCustomerInformationType = CustomerInformationType.Phone;
      }

      storedComboFiltersObj.filterConversationId = '';
      this.form.setValue(storedComboFiltersObj);
      localStorage.setItem(
        this.dashboardComboFiltersModelKey,
        JSON.stringify(this.form.getRawValue())
      );
    }
  }

  private getUpdatedCascadingVirtualAgents(projectIds: any[]) {
    return this.allProjects
      .filter((p) => projectIds?.includes(p.id))
      .reduce((acc, val) => acc.concat(val.virtualAgents), []);
  }
}
