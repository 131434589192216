import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ProjectDto } from 'src/app/projects/proxy/project.models';
import {
  GetIntentsInput,
  IntentWithNavigationPropertiesDto,
  VirtualAgentStatusDto,
} from 'src/app/virtual-agents/proxy';
import { EMPTY_GUID } from '../../shared.consts';
import {
  DeletionStatusDto,
  ContextCreateDto,
  ContextUpdateDto,
  CustomDataInfo,
  ContextDto,
  FlowDto,
  Flows,
  FlowUpdateDto,
  MandatoryFieldsResponseDto,
  NodeModel,
  ProjectVersionDto,
  UiPaletteOutputDto,
  RenameFlowCompletedEventArgs,
  FlowNameUpdateDto,
} from './designer.model';
import { Subject } from 'rxjs';

const FLOWS_PATH = '/api/app/flows';
const PROJECT_VERSION = '/api/app/projectVersions';
const PROJECT_PATH = '/api/app/projects';
const CONTEXT_PATH = '/api/app/contexts';

@Injectable({
  providedIn: 'root',
})
export class DesignerService {
  apiName = 'Default';

  selectedFlowChangedSubject = new Subject<string>();
  navigateToSelectedFlowSubject = new Subject<string>();
  createFlowCompletedSubject = new Subject<Flows>();
  deleteFlowCompletedSubject = new Subject<string>();
  renameFlowCompletedSubject = new Subject<RenameFlowCompletedEventArgs>();

  getPaletteModels = (id: string, isActionFlow: boolean = false) =>
    this.restService.request<void, UiPaletteOutputDto[]>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/node-palette/${id}?isActionFlow=${isActionFlow}`,
      },
      { apiName: this.apiName }
    );

  getFlowsByProjectVersionId = (projectVersionId: string) =>
    this.restService.request<void, ProjectVersionDto>(
      {
        method: 'GET',
        url: `${PROJECT_VERSION}/${projectVersionId}`,
      },
      { apiName: this.apiName }
    );

  getSubFlowNamesByProjectVersionId = (projectVersionId: string) =>
    this.restService.request<void, string[]>(
      {
        method: 'GET',
        url: `${PROJECT_VERSION}/${projectVersionId}/subflow-names`,
      },
      { apiName: this.apiName }
    );

  getCustomDataInfo = (parentId: string, isAction: boolean) =>
    this.restService.request<void, CustomDataInfo>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/custom-data-info/${parentId}?isAction=${isAction}`,
      },
      { apiName: this.apiName }
    );

  getFlowById = (id: string) =>
    this.restService.request<void, FlowDto>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/${id}`,
      },
      { apiName: this.apiName }
    );

  updateFlowJson = (params: any) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${FLOWS_PATH}/update-flow-json`,
        body: params,
      },
      { apiName: this.apiName }
    );

  updateSubFlows = (params: FlowUpdateDto) =>
    this.restService.request<FlowUpdateDto, Flows>(
      {
        method: 'PUT',
        url: `${FLOWS_PATH}/${params.id}`,
        body: params,
      },
      { apiName: this.apiName }
    );

  updateSubFlowName = (id: string, params: FlowNameUpdateDto) =>
    this.restService.request<FlowNameUpdateDto, Flows>(
      {
        method: 'PATCH',
        url: `${FLOWS_PATH}/${id}/name`,
        body: params,
      },
      { apiName: this.apiName }
    );

  createSubFlows = (params: FlowUpdateDto) =>
    this.restService.request<FlowUpdateDto, Flows>(
      {
        method: 'POST',
        url: FLOWS_PATH,
        body: params,
      },
      { apiName: this.apiName }
    );

  deleteSubFlows = (id: string) =>
    this.restService.request<void, void>(
      {
        method: 'DELETE',
        url: `${FLOWS_PATH}/${id}`,
      },
      { apiName: this.apiName }
    );

  getAvailableVirtualAgents = (parentId: string, isAction: boolean) =>
    this.restService.request<void, VirtualAgentStatusDto[]>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/available-virtual-agents/${parentId}?isAction=${isAction}`,
      },
      { apiName: this.apiName }
    );

  getVoiceBiometryIntegrationDefaultConfidenceThreshold = (
    parentId: string,
    isAction: boolean = false
  ) =>
    this.restService.request<void, string>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/${parentId}/voice-biometrics/default-confidence-threshold?isActionFlow=${isAction}`,
      },
      { apiName: this.apiName }
    );

  writeAudioFileToS3Async = (file: File, key: string) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('key', key);

    return this.restService.request<FormData, void>(
      {
        method: 'POST',
        url: `${FLOWS_PATH}/audio-file`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  writeFileToS3Async = (file: File, key: string) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('key', key);

    return this.restService.request<FormData, void>(
      {
        method: 'POST',
        url: `${FLOWS_PATH}/file`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  readFileFromS3Async = (key: string) => {
    return this.restService.request<any, File>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/file/${key}`,
        responseType: 'blob' as 'json',
      },
      { apiName: this.apiName, skipHandleError: true }
    );
  };

  exportFlow = (id: string) =>
    this.restService.request<void, any>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/export/${id}`,
      },
      { apiName: this.apiName }
    );

  getIntentsOfAvailableVirtualAgents = (input: GetIntentsInput, vId: string) =>
    this.restService.request<void, PagedResultDto<IntentWithNavigationPropertiesDto>>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/available-virtual-agents/${vId}/intents`,
        params: {
          filterText: input.filterText,
          name: input.name,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  checkMandatoryFields = (flowId: string, isProject: boolean) =>
    this.restService.request<void, MandatoryFieldsResponseDto>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/check-mandatory-fields/${flowId}?isProject=${isProject}`,
      },
      { apiName: this.apiName }
    );

  checkNodeValidations = (nodeData: NodeModel, agentId: string) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url:
          agentId !== null
            ? `${FLOWS_PATH}/check-node-validations/${agentId}`
            : `${FLOWS_PATH}/check-node-validations/${EMPTY_GUID}`,
        body: nodeData,
      },
      { apiName: this.apiName }
    );

  getLanguageNames = (parentId: string, isAction: boolean) =>
    this.restService.request<void, string[]>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/language-names/${parentId}?isAction=${isAction}`,
      },
      { apiName: this.apiName }
    );

  getSkills = (parentId: string, isAction: boolean) =>
    this.restService.request<void, string[]>(
      {
        method: 'GET',
        url: `${FLOWS_PATH}/skills/${parentId}?isAction=${isAction}`,
      },
      { apiName: this.apiName }
    );

  publish = (id: string) =>
    this.restService.request<void, ProjectDto>(
      {
        method: 'POST',
        url: `${PROJECT_PATH}/${id}/publish`,
      },
      { apiName: this.apiName }
    );

  // Context

  getProjectContextObjects = (projectVersionId: string, intentId: string = null) =>
    this.restService.request<void, ContextDto>(
      {
        method: 'GET',
        url: intentId
          ? `${CONTEXT_PATH}/${projectVersionId}?intentId=${intentId}`
          : `${CONTEXT_PATH}/${projectVersionId}`,
      },
      { apiName: this.apiName }
    );

  checkIfContextIsDeletable = (id) =>
    this.restService.request<void, DeletionStatusDto>(
      {
        method: 'POST',
        url: `${CONTEXT_PATH}/check-if-deletable/${id}`,
      },
      { apiName: this.apiName }
    );

  deleteContext = (id) =>
    this.restService.request<void, boolean>(
      {
        method: 'DELETE',
        url: `${CONTEXT_PATH}/${id}`,
      },
      { apiName: this.apiName }
    );

  editContext = (id: string, params: ContextUpdateDto) =>
    this.restService.request<ContextUpdateDto, ContextDto>(
      {
        method: 'PUT',
        url: `${CONTEXT_PATH}/${id}`,
        body: params,
      },
      { apiName: this.apiName }
    );

  createContextObjects = (id: string, params: ContextCreateDto) =>
    this.restService.request<ContextCreateDto, ContextDto>(
      {
        method: 'POST',
        url: `${CONTEXT_PATH}/${id}`,
        body: params,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
